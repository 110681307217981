<template>
  <v-card flat class="pa-0 ma-0">
    <!-- <v-col cols="12">
      v-if="vueUsuarioPermissoesEdit"

      <v-card class="pa-3 d-md-flex align-center justify-space-between" flat>
        <v-card-text class="text-body-2 pa-0">
          {{ $tc("configuracoes.copiarpermissoes") }}
          Copiar Permissões
        </v-card-text>
        <v-select
          class="grow text-body-2 mt-4 mt-md-0"
          v-model="selectedUserId"
          :items="usuarios"
          item-text="name"
          item-value="id"
          label="usuario"
          v-on:change="getCopyPermissoes"
          outlined
          dense
          hide-details
          clearable
        ></v-select>
        <v-btn
          depressed
          class="mt-6 mt-md-0 ml-md-4"
          @click="copiarPermissoes()"
        >
          copiar
        </v-btn>
      </v-card>
    </v-col> -->

    <div>
      <v-text-field
        v-model="search"
        append-icon="mdi-search"
        label="buscar"
        clearable
        single-line
        outlined
        dense
        hide-details
        class="mb-4 mx-5"
      ></v-text-field>
    </div>

    <div style="height: calc(100vh - 290px); overflow-y: auto">
      <v-treeview
        :active.sync="active"
        :open.sync="open"
        :items="permissoes"
        :search="search"
        @update:active="getPermissao()"
        item-text="descricao"
        item-key="id"
        item-children="children"
        transition
        activatable
        color="primary"
        hoverable
      >
        <template v-slot:prepend="{ item, open }">
          <v-icon>
            {{ open ? "mdi-folder-open" : "mdi-folder" }}
          </v-icon>
        </template>
        <template v-slot:label="{ item }">
          <div class="text-body-2">{{ item.descricao }}</div>
        </template>
        <template v-if="$vuetify.breakpoint.mdAndUp" v-slot:append="{ item }">
          <v-divider vertical class=""></v-divider>
          <span class="text-caption">{{ item.descricao_detalhe }}</span>
        </template>
      </v-treeview>
    </div>

    <v-dialog v-model="dialog" max-width="400px">
      <!-- :disabled="!vueUsuarioPermissoesEdit" -->
      <v-card flat>
        <v-card-title class="text-subtitle-1 accent">
          {{ permissao.descricao }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          {{ permissao.descricao_detalhe }}
        </v-card-text>
        <v-card-text v-if="checkTotalPermissoes(permissao.bits)">
          <v-card
            class="d-flex align-center justify-space-between my-2 px-4 py-2"
            flat
            color="accent"
            v-if="checkMenu(permissao.bits)"
          >
            <v-subheader
              style="height: auto !important"
              class="ma-0 pa-0 text-body-2 font-weight-medium text-uppercase"
            >
              Menu
            </v-subheader>
            <v-switch
              class="ma-0"
              hide-details
              :false-value="0"
              :true-value="128"
              v-model="bitWise.menu"
            ></v-switch>
          </v-card>

          <v-card
            flat
            class="d-flex aling-center justify-space-between my-2 px-4 py-2"
            color="accent"
            v-if="checkVisualizar(permissao.bits)"
          >
            <v-subheader
              style="height: auto !important"
              class="ma-0 pa-0 text-body-2 font-weight-medium text-uppercase"
            >
              Visualizar
            </v-subheader>

            <v-switch
              class="ma-0"
              hide-details
              :false-value="0"
              :true-value="1"
              v-model="bitWise.visualizar"
            ></v-switch>
          </v-card>

          <v-card
            flat
            class="d-flex aling-center justify-space-between my-2 px-4 py-2"
            color="accent"
            v-if="checkAdicionar(permissao.bits)"
          >
            <v-subheader
              style="height: auto !important"
              class="ma-0 pa-0 text-body-2 font-weight-medium text-uppercase"
            >
              Adicionar
            </v-subheader>

            <v-switch
              class="ma-0"
              hide-details
              :false-value="0"
              :true-value="2"
              v-model="bitWise.adicionar"
            ></v-switch>
          </v-card>

          <v-card
            flat
            class="d-flex aling-center justify-space-between my-2 px-4 py-2"
            color="accent"
            v-if="checkEditar(permissao.bits)"
          >
            <v-subheader
              style="height: auto !important"
              class="ma-0 pa-0 text-body-2 font-weight-medium text-uppercase"
            >
              Editar
            </v-subheader>

            <v-switch
              class="ma-0"
              hide-details
              :false-value="0"
              :true-value="4"
              v-model="bitWise.editar"
            ></v-switch>
          </v-card>

          <v-card
            flat
            class="d-flex aling-center justify-space-between my-2 px-4 py-2"
            color="accent"
            v-if="checkDeletar(permissao.bits)"
          >
            <v-subheader
              style="height: auto !important"
              class="ma-0 pa-0 text-body-2 font-weight-medium text-uppercase"
            >
              Deletar
            </v-subheader>

            <v-switch
              class="ma-0"
              hide-details
              :false-value="0"
              :true-value="8"
              v-model="bitWise.deletar"
            ></v-switch>
          </v-card>

          <v-card
            flat
            class="d-flex aling-center justify-space-between my-2 px-4 py-2"
            color="accent"
            v-if="checkImprimir(permissao.bits)"
          >
            <v-subheader
              style="height: auto !important"
              class="ma-0 pa-0 text-body-2 font-weight-medium text-uppercase"
            >
              Imprimir
            </v-subheader>

            <v-switch
              class="ma-0"
              hide-details
              :false-value="0"
              :true-value="16"
              v-model="bitWise.imprimir"
            ></v-switch>
          </v-card>

          <v-card
            flat
            class="d-flex aling-center justify-space-between my-2 px-4 py-2"
            color="accent"
            v-if="checkExportar(permissao.bits)"
          >
            <v-subheader
              style="height: auto !important"
              class="ma-0 pa-0 text-body-2 font-weight-medium text-uppercase"
            >
              Exportar
            </v-subheader>

            <v-switch
              class="ma-0"
              hide-details
              :false-value="0"
              :true-value="32"
              v-model="bitWise.exportar"
            ></v-switch>
          </v-card>

          <v-card
            flat
            class="d-flex aling-center justify-space-between my-2 px-4 py-2"
            color="accent"
            v-if="checkLimitar(permissao.bits)"
          >
            <v-subheader
              style="height: auto !important"
              class="ma-0 pa-0 text-body-2 font-weight-medium text-uppercase"
            >
              Limitar
            </v-subheader>

            <v-switch
              class="ma-0"
              hide-details
              :false-value="0"
              :true-value="64"
              v-model="bitWise.limitar"
            ></v-switch>
          </v-card>
        </v-card-text>
        <v-scroll-y-transition mode="out-in">
          <v-card-text class="py-0 mb-6" v-if="bitWise.limitar">
            <v-row>
              <v-col>
                <v-text-field
                  v-model="userPermissao.valor"
                  label="Valor"
                  type="number"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="userPermissao.operador"
                  label="Operador"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-scroll-y-transition>

        <v-card-actions class="mt-10">
          <v-spacer></v-spacer>
          <!-- v-if="vueUsuarioPermissoesEdit" -->
          <v-btn
            @click="updateUserPermission(userPermissao.id)"
            depressed
            dark
            small
            color="secondary"
          >
            salvar
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { getPermissao, getPermissoes } from "@/api/admin/permissoes.js";

import {
  getUsuarioPermissoes,
  putPermissaoUsuario,
  postPermissaoUsuario,
} from "@/api/admin/users_permissoes.js";

import { getUsuarios } from "@/api/admin/users.js";
import updateBitMask from "@/mixins/configuracoes/permissoes/updateBitMask.js";
import checkPermissions from "@/mixins/configuracoes/permissoes/checkPermissions.js";
// import copyUserPermissions from "@/mixins/configuracoes/permissoes/copyUserPermissions.js";

// import { mapGetters } from "vuex";

export default {
  name: "UsuarioPermissoes",
  mixins: [updateBitMask, checkPermissions],
  props: {
    usuarioId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    search: "",
    dialog: false,
    active: [],
    open: [],
    valid: false,
    permissoes: [],
    permissao: {
      bits: 0,
    },
    original: {},
    userPermissoes: [],
    copiaUserPermissoes: [],
    userPermissao: {
      nivel_acesso: 0,
      valor: null,
      operador: null,
    },
    usuarios: [],
    selectedUserId: null,
  }),
  computed: {
    // ...mapGetters("Componentes", ["getAccess"]),
    // vueUsuarioPermissoesEdit() {
    //   return this.getAccess("vueUsuarioPermissoes", "editar");
    // },
  },
  methods: {
    goToPermissao(item) {
      this.$router.push({
        name: "Permissao",
        params: { permissaoId: item },
      });
    },
    getPermissaoPai(permissao_id) {
      let permissao_pai = "";
      this.permissoes.forEach((permissao) => {
        if (permissao.id == permissao_id) {
          permissao_pai = ". " + permissao.descricao;
        }
      });
      return permissao_pai;
    },
    getPermissao() {
      this.bitWise.visualizar = 0;
      this.bitWise.adicionar = 0;
      this.bitWise.editar = 0;
      this.bitWise.deletar = 0;
      this.bitWise.imprimir = 0;
      this.bitWise.exportar = 0;
      this.bitWise.limitar = 0;
      this.bitWise.menu = 0;

      this.userPermissao = {};

      if (this.active[0]) {
        getPermissao(this.active[0])
          .then((response) => {
            this.permissao = response;
            this.dialog = true;
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.$toast.error(
                this.$tc("configuracoes.permissaonaoencontrada")
              );
            }
            console.error(error);
          });
        this.userPermissoes?.forEach((permissao) => {
          if (this.active[0] === permissao.permissao_id) {
            this.userPermissao = permissao;
            this.updateBitMasks(this.userPermissao.nivel_acesso);
            this.original = { ...permissao };
          }
        });
      }
    },
    fetchUsuarioPermissoes(userId) {
      if (userId) {
        getUsuarioPermissoes(userId)
          .then((response) => {
            this.userPermissoes = response;
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.notFound = "Permissao nao Econtrada";
            }
            console.error(error);
          });
      }
    },
    updateUserPermission(id) {
      this.userPermissao.nivel_acesso =
        this.bitWise.visualizar +
        this.bitWise.adicionar +
        this.bitWise.editar +
        this.bitWise.deletar +
        this.bitWise.imprimir +
        this.bitWise.exportar +
        this.bitWise.limitar +
        this.bitWise.menu;

      if (this.bitWise.limitar === 0) {
        this.userPermissao.valor = null;
        this.userPermissao.operador = null;
      }

      let permIgual = this.userPermissoes?.find(
        (perm) => perm.permissao_id === this.permissao.id
      );

      if (permIgual) {
        if (
          JSON.stringify(this.userPermissao) == JSON.stringify(this.original)
        ) {
          this.$toast.error("Por favor, faça uma mudança antes de atualizar!");
        } else {
          putPermissaoUsuario(id, this.userPermissao)
            .then((response) => {
              if (response.status === 201) {
                this.$toast.success("Atualizado com sucesso!");
                this.original = { ...this.userPermissao };
                this.fetchUsuarioPermissoes(this.usuarioId);
                this.dialog = false;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        postPermissaoUsuario(
          this.userPermissao,
          this.usuarioId,
          this.permissao.id
        )
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Criado Sucesso");
              this.original = { ...this.userPermissao }; //
              this.fetchUsuarioPermissoes(this.usuarioId);
              this.dialog = false;
            }
          })
          .catch((error) => {
            console.log(error.response);
            if (error.response.status === 422) {
              this.$toast.error("Alteração salva");
            }
          });
      }
    },
  },
  mounted() {
    // Pega todas as permissoes existentes
    getPermissoes()
      .then((response) => {
        this.permissoes = response;
        this.permissoes?.forEach((permissao) => {
          this.open.push(permissao.id);
          permissao.children?.forEach((child) => {
            this.open.push(child.id);
          });
        });
      })
      .catch((error) => {
        console.error(error);
      });

    // Todas as permissoes do USUARIO

    this.fetchUsuarioPermissoes(this.usuarioId);

    // Todos os usuarios
    getUsuarios()
      .then((response) => {
        this.usuarios = response;
        let removeIndex = this.usuarios
          .map((item) => item.id)
          .indexOf(this.usuarioId);
        this.usuarios.splice(removeIndex, 1);
      })
      .catch((error) => console.log(error));
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-treeview-node {
  cursor: pointer;
  // background: #f9f9f9;
  margin: 10px 0;
  border-radius: 4px;
  // border: thin solid rgba(0, 0, 0, 0.08);

  .v-treeview-node__children {
    .v-treeview-node {
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: thin solid rgba(0, 0, 0, 0.08);
      border-radius: 0px;
      margin: 0;

      &:first-of-type {
        border-top: thin solid rgba(0, 0, 0, 0.08);
      }
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}
</style>
